<template>
  <!-- 运营管理 -->
  <div class="padding24" id="banner_list">
    <div v-show="$route.meta.isShow">
      <div class="top">
        <div class="left">
          <a-button
            v-if="isShowAdd"
            icon="plus"
            type="primary"
            @click="addClick"
          >
            添加轮播图
          </a-button>
        </div>
        <div class="right"></div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
        :customRow="customRow"
      >
        <img
          v-viewer
          class="avatar"
          slot="imageUrl"
          slot-scope="imageUrl, record"
          :src="record.imageUrl"
        />
        <span slot="operation" slot-scope="operation, record">
          <a
            v-if="isShowUpd"
            class="margin_right20"
            @click="detailClick(record)"
          >
            详情
          </a>
          <a v-if="isShowDel" @click="delClick(record)">删除</a>
        </span>
      </a-table>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  BannerListApi,
  BannerDelApi,
  BannerSortApi,
} from "@/request/api/operate.js";
import { Modal, message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  created() {
    if (codeFn("/admin/banner/add")) this.isShowAdd = true;
    if (codeFn("/admin/banner/upd")) this.isShowUpd = true;
    if (codeFn("/admin/banner/del")) this.isShowDel = true;
  },
  data() {
    return {
      getData: {
        pageNo: 1,
        pageSize: 20,
      },
      columns: [
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
          width: "30%",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "图片",
          dataIndex: "imageUrl",
          key: "imageUrl",
          width: "30%",
          scopedSlots: { customRender: "imageUrl" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "20%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      tableLoading: false,
      isShowAdd: false,
      isShowUpd: false,
      isShowDel: false,
      sourceObj: {}, // 表格源对象
      sourceIndex: null, // 表格源对象索引
      targetObj: {}, // 表格目标对象
      targetIndex: null, // 表格目标对象索引
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path == "/operate/bannerList") {
          this.listFn();
        }
      },
      immediate: true,
    },
  },
  methods: {
    addClick() {
      this.$router.push(`/operate/bannerList/addBanner/0`);
    },
    detailClick(record) {
      this.$router.push(`/operate/bannerList/addBanner/${record.id}`);
    },
    delClick(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复，是否确认删除？",
        okType: "danger",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          BannerDelApi({ id: record.id }).then(({ code }) => {
            if (code == 200) {
              message.success("删除成功", 2);
              let tableData = that.tableData;
              that.tableData = tableData.filter(
                (item) => item.id !== record.id
              );
            }
          });
        },
      });
    },
    listFn() {
      this.tableLoading = false;
      BannerListApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.map((item, index) => {
            item.key = index;
          });
          this.tableData = data;
        } else {
          this.tableLoading = false;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#banner_list");
    },
    // 表格拖动
    customRow(record, index) {
      return {
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            var ev = event || window.event; // 兼容IE
            ev.target.draggable = true;
          }, // 开始拖拽
          dragstart: (event) => {
            var ev = event || window.event; // 阻止冒泡    // 兼容IE
            ev.stopPropagation(); // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          }, // 拖动元素经过的元素
          dragover: (event) => {
            var ev = event || window.event; // 阻止默认行为     // 兼容 IE
            ev.preventDefault();
          }, // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event; // 阻止冒泡
            ev.stopPropagation(); // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            this.tableData.splice(this.sourceIndex, 1);
            this.tableData.splice(this.targetIndex, 0, this.sourceObj);
            let arr = [];
            this.tableData.map((item, index) => {
              arr.push({ id: item.id, sort: index });
            });
            BannerSortApi(arr).then(({ code, data }) => {
              if (code == 200) {
                message.success("排序成功", 1);
              }
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
#banner_list {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .right {
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
  }
}
/deep/.ant-table-wrapper {
  margin-top: 20px;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  text-align: center;
}
/deep/.ant-table-row-cell-break-word {
  text-align: center;
}
.avatar {
  width: 480px;
  height: 150px;
  object-fit: contain;
}
</style>
